import Vue from 'vue'
import $ from 'jquery'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'
import Swal from 'sweetalert2'
import imgError from '@/assets/admin/images/error.png'

Vue.use(Vuex)

const Toast = Swal.mixin({
   toast: true,
   position: 'top-end',
   showConfirmButton: false,
   timer: 3500,
   timerProgressBar: true,
   didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
   }
})

export default new Vuex.Store({
   state: {
      // urlRest: 'http://192.168.5.116:9090/ecommerce-1.0.0/',
      urlRest: 'https://monkeycity.com.br/Monkey-Ecommerce/',
      urlRestExterna: 'https://monkeycity.com.br/Monkey-Ecommerce/',
      listaCategorias: [],
      listaProdutos: {'um': [], 'dois': [], 'tres': [], 'quatro': []},
      isRouteAdmin: false,
      isCarregando: false,
      logado: {'cliente': false, 'admin': false, 'token': ''},
      dadosUsuario: {'cliente': {}, 'admin': {}},
      carrinho: {'itens': [], 'valorTotal': 0, 'quantidadeTotal': 0, 'toggle': false},
      pesquisa: {'resultado': [], 'categoria': null, 'subcategoria': null, 'listaSubcategorias': []},
      countPedidosPendentes: 0,
      config: {'urlDiscord': null,'urlTiktok': null,'urlYoutube': null,'urlInstagram': null,'urlFacebook': null,'urlContato': null,'logoBlack': null,'logoWhite': null,
         'banner1': null,'banner2': null,'banner3': null,'banner4': null,'banner5': null,'banner6': null,'banner7': null,'descricao1': null,'descricao2': null,
         'descricao3': null,'descricao4': null, 'apiKeyPagHiper': null, 'tokenPagHiper': null,'clientIdPayPal': null, 'tokenMercadoPago': null},
      listaVeiculos: [],
      listaPermissoes: [],
      listaVips: [],
      urlFotoVeiculos: 'https://j4v4.site/MC/carros/icons/',
      urlFotoItens: 'https://j4v4.site/MC/itens/icons/'
   },
   mutations: {
      isRouteAdmin : (state, isAdmin) => {
         state.isRouteAdmin = isAdmin
      },
      imageError : (state, e) => {
         e.target.src = imgError
      },
      login : (state, credenciais) => {
         if (sessionStorage.usernameCliente != undefined && sessionStorage.senhaCliente != undefined) {
            credenciais = {
               "usuario": sessionStorage.usernameCliente,
               "senha": sessionStorage.senhaCliente
            }
         }

         if (credenciais == undefined) {
            return
         } else {
            $("#loading").fadeIn();
         }

         axios({
            method: 'get',
            url: state.urlRest +'user/login',
            params: {
               login: credenciais.usuario,
               password: credenciais.senha
            }
         }).then(function (response) {
            state.dadosUsuario.cliente = response.data
            state.logado.cliente = true

            sessionStorage.usernameCliente = credenciais.usuario
            sessionStorage.senhaCliente = credenciais.senha

            router.push('/').catch(function(){})

         }).catch(function (error) {
            state.dadosUsuario.cliente = {}
            state.logado.cliente = false
            sessionStorage.removeItem('usernameCliente')
            sessionStorage.removeItem('senhaCliente')

            if (error.response != undefined) {
               if (error.response.status == 401) {
                  Toast.fire({
                     icon: 'error',
                     title: 'Credenciais incorretas!'
                  });
               } else {
                  Toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            $("#loading").fadeOut();
         });
      },
      loginAdmin : (state, credenciais) => {
         if (sessionStorage.username != undefined && sessionStorage.senha != undefined) {
            credenciais = {
               "usuario": sessionStorage.username,
               "senha": sessionStorage.senha
            }
         }

         if (credenciais == undefined) {
            return
         } else {
            state.isCarregando = true
         }

         axios({
            method: 'get',
            url: state.urlRest +'adminuser/login',
            params: {
               username: credenciais.usuario,
               password: credenciais.senha
            }
         }).then(function (response) {
            state.dadosUsuario.admin = response.data
            state.logado.token = response.data.tokenSessao
            state.logado.admin = true

            sessionStorage.username = credenciais.usuario
            sessionStorage.senha = credenciais.senha

            router.push('/admPaginaInicial').catch(function(){})

         }).catch(function (error) {
            state.dadosUsuario.admin = {}
            state.logado.token = ''
            state.logado.admin = false
            sessionStorage.removeItem('username')
            sessionStorage.removeItem('senha')

            if (error.response != undefined) {
               if (error.response.status == 401) {
                  Toast.fire({
                     icon: 'error',
                     title: 'Credenciais incorretas!'
                  });
               } else {
                  Toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            state.isCarregando = false
         });
      },
      deslogar : state => {
         state.dadosUsuario.cliente = {}
         state.logado.cliente = false

         sessionStorage.removeItem('usernameCliente')
         sessionStorage.removeItem('senhaCliente')

         router.push('/')
      },
      deslogarAdmin : function () {
         sessionStorage.removeItem('username')
         sessionStorage.removeItem('senha')

         window.location.reload()
      },
      alternarTelaCarregamento : (state, isCarregando) => {
         state.isCarregando = isCarregando;

         if (isCarregando) {
            $("#loading").fadeIn();

         } else {
            $("#loading").fadeOut();
         }
      },
		saveData : (state, json) => {
         state.listaCategorias = json.categorias
         state.listaProdutos.um = json.um
         state.listaProdutos.dois = json.dois
         state.listaProdutos.tres = json.tres
         state.listaProdutos.quatro = json.quatro
         state.config = json.config
      },
		pesquisar : (state, json) => {
         $("#loading").fadeIn();

         state.pesquisa.listaSubcategorias = state.listaCategorias.filter(obj => obj.categoria == json.categoria).map(obj => obj.subcategorias)[0]
         state.pesquisa.categoria = json.categoria
         state.pesquisa.subcategoria = json.subcategoria

         axios({
            method: 'get',
            url: state.urlRest +'product/search',
            params: {
               categoria: json.categoria,
               nomeProduto: json.valor,
               subcategoria: json.subcategoria
            }
         }).then(response => {
            state.pesquisa.resultado = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               Toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               Toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            $("#loading").fadeOut();
            $(".offcanvas__area").removeClass("opened");
            $(".body-overlay").removeClass("opened");

            router.push('/produtos').catch(function(){})

            setTimeout(() => {
               document.body.scrollTop = 0;
               document.documentElement.scrollTop = 0;
            }, 250);
         });
      },
      addCarrinho : (state, produto) => {
         let carrinho = localStorage.carrinho != undefined ? JSON.parse(localStorage.carrinho) : []
         
         carrinho.unshift(produto)
         localStorage.carrinho = JSON.stringify(carrinho)
         state.carrinho.itens = carrinho
      },
      removeCarrinho : (state, index) => {
         let carrinho = localStorage.carrinho != undefined ? JSON.parse(localStorage.carrinho) : []

         carrinho.splice(index, 1) 
         localStorage.carrinho = JSON.stringify(carrinho)
         state.carrinho.itens = carrinho
      },
      alterarQuantidadeCarrinho : (state, json) => {
         let carrinho = localStorage.carrinho != undefined ? JSON.parse(localStorage.carrinho) : []

         carrinho[json.index].quantidade = json.quantidade
         
         localStorage.carrinho = JSON.stringify(carrinho)
         state.carrinho.itens = carrinho
         state.carrinho.valorTotal = carrinho.map(produto => parseFloat(produto.preco) * parseFloat(produto.quantidade)).reduce((total, valor) => total += valor)
      },
      limparCarrinho : state => {
         let carrinho = []

         localStorage.carrinho = JSON.stringify(carrinho)
         state.carrinho = {'itens': [], 'valorTotal': 0, 'quantidadeTotal': 0, 'toggle': false}
      },
      configurarCarrinho : state => {
         try {
            state.carrinho.valorTotal = JSON.parse(localStorage.carrinho).map(produto => parseFloat(produto.preco) * parseFloat(produto.quantidade)).reduce((total, valor) => total += valor)
            state.carrinho.quantidadeTotal = JSON.parse(localStorage.carrinho).length
            state.carrinho.itens = JSON.parse(localStorage.carrinho)

         } catch (e) {
            state.carrinho = {'itens': [], 'valorTotal': 0, 'quantidadeTotal': 0}
         }
         
         if (state.carrinho.itens.length == 0) {
            router.push('/').catch(function(){})
         }
      },
      toggleCarrinho : (state, toggle) => {
         state.carrinho.toggle = toggle

         if (toggle) {
            $(".cart__toggle").addClass("cart__toggle-open");

         } else {
            $(".cart__toggle").removeClass("cart__toggle-open");
            $(".cart__mini").removeClass("cart__opened");
         }

         $(".cart__mini.cart__opened").animate({ scrollTop: $(".cart__mini.cart__opened ul").height() }, 0);
      },
      buscarPedidosPendentes : state => {
         return new Promise(function() {
            axios({
               method: 'get',
               url: state.urlRest +'adminproduct/checkpendingorders',
               headers: {
                  'Authorization': 'Bearer ' + state.logado.token +'@@@'+ state.dadosUsuario.admin.usuarioUsername 
               }
            }).then(response => {
               state.countPedidosPendentes = parseInt(response.data)

            }).catch(function (error) {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     state.dadosUsuario.admin = {}
                     state.logado.admin = false
                     state.logado.token = false

                     sessionStorage.removeItem('username')
                     sessionStorage.removeItem('senha')

                     router.push('/')

                     Toast.fire({
                        icon: 'warning',
                        title: 'Sessão expirada!'
                     });
                  } else {
                     Toast.fire({
                        icon: 'error',
                        title: 'Erro: ' + error.response.status
                     });
                  }
               } else {
                  Toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            });
         })
      },
      buscarPermissoes : state => {
         axios({
            method: 'get',
            url: state.urlRest +'game/getPermissions',
            headers: {
               'Authorization': 'Bearer ' + state.logado.token +'@@@'+ state.dadosUsuario.admin.usuarioUsername
            }
         }).then(response => {
            state.listaPermissoes = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  sessionStorage.removeItem('username')
                  sessionStorage.removeItem('senha')
                  window.location.reload()

                  Toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  Toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         });
      },
      buscarVeiculos : state => {
         axios({
            method: 'get',
            url: state.urlRest +'game/getVehicles',
            headers: {
               'Authorization': 'Bearer ' + state.logado.token +'@@@'+ state.dadosUsuario.admin.usuarioUsername
            }
         }).then(response => {
            state.listaVeiculos = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  sessionStorage.removeItem('username')
                  sessionStorage.removeItem('senha')
                  window.location.reload()

                  Toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  Toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         });
      },
      buscarVips : state => {
         axios({
            method: 'get',
            url: state.urlRest +'game/getVips',
            headers: {
               'Authorization': 'Bearer ' + state.logado.token +'@@@'+ state.dadosUsuario.admin.usuarioUsername
            }
         }).then(response => {
            response.data.forEach(vip => {
               state.listaVips.push(vip.perm)
            });
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  sessionStorage.removeItem('username')
                  sessionStorage.removeItem('senha')
                  window.location.reload()

                  Toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  Toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         });
      }
   },
   actions: {
      isRouteAdmin : ({commit}, isAdmin) => {
         commit('isRouteAdmin', isAdmin)
      },
      imageError : ({commit}, e) => {
         commit('imageError', e)
      },
      pesquisar : ({commit}, json) => {
         commit('pesquisar', json)
      },
      toggleCarrinho : ({commit}, toggle) => {
         commit('toggleCarrinho', toggle)
      },
      login : ({commit}, credenciais) => {
         commit('login', credenciais)
      },
      loginAdmin : ({commit}, credenciais) => {
         commit('loginAdmin', credenciais)
      },
      alternarTelaCarregamento : ({commit}, isCarregando) => {
         commit('alternarTelaCarregamento', isCarregando)
      },
      addCarrinho : ({commit}, produto) => {
         commit('addCarrinho', produto)
      },
      removeCarrinho : ({commit}, index) => {
         commit('removeCarrinho', index)
      },
      alterarQuantidadeCarrinho : ({commit}, json) => {
         commit('alterarQuantidadeCarrinho', json)
      },
      buscarPermissoes : context => context.commit('buscarPermissoes'),
      buscarVeiculos : context => context.commit('buscarVeiculos'),
      buscarVips : context => context.commit('buscarVips'),
      buscarPedidosPendentes : context => context.commit('buscarPedidosPendentes'),
      configurarCarrinho : context => context.commit('configurarCarrinho'),
      limparCarrinho : context => context.commit('limparCarrinho'),
      deslogarAdmin : context => context.commit('deslogarAdmin'),
      deslogar : context => context.commit('deslogar'),
      saveData : ({commit}, json) => {
         commit('saveData', json)
      }
   },
   modules: {
   }
})